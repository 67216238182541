import React, { FC } from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { Routes } from "../constants/Routes"

const NotFoundPage: FC = () => (
  <Layout location={Routes.FOUR_0_FOUR}>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
